import React, { Fragment, useState, useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import { pathOr } from 'ramda'
import { InstantSearch, Configure, ScrollTo } from 'react-instantsearch/dom'
import {
  connectHits,
  connectStats,
  connectStateResults,
  connectSearchBox
} from 'react-instantsearch/connectors'
import { Theme } from '@pga/pga-component-library'
import { InView } from 'react-intersection-observer'
import searchInsights from 'search-insights'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Pagination from '../components/Algolia/Pagination'

const config = {
  apiKey: 'ee3d91f879be4b4796c96f10a1d3b623',
  appId: '0TSYHLZXNU',
  indexName: 'test_global_search'
}

searchInsights('init', {
  appId: config.appId,
  apiKey: config.apiKey
})

export const sendEventView = (eventName, hits) => {
  searchInsights('viewedObjectIDs', {
    userToken: 'anonymous-resources-search',
    eventName,
    index: config.indexName,
    objectIDs: hits
  })
}

export const sendEventClick = (eventName, hit) => {
  searchInsights('clickedObjectIDsAfterSearch', {
    userToken: 'anonymous-resources-search',
    eventName,
    index: config.indexName,
    queryID: hit.__queryID,
    objectIDs: [hit.objectID],
    positions: [hit.__position]
  })
}

export const formatCategory = category =>
  category
    ? category
        .split('-')
        .map(item => item.charAt(0).toUpperCase() + item.slice(1))
        .join(' ')
    : null

export const _SearchStats = ({ nbHits, searchResults }) => {
  if (!searchResults) return null
  if (searchResults.query) {
    return (
      <div className='row mx-0 py-2'>
        <div className='col-8'>
          <span>
            <strong>{nbHits} </strong>
            results for:
            <strong> {searchResults.query}</strong>
          </span>
        </div>
      </div>
    )
  }
  return null
}
const SearchStats = connectStateResults(connectStats(_SearchStats))

export const _ResultCard = ({ hits, sendEventClick, sendEventView }) => {
  const [viewedHits, setViewedHits] = useState([])
  const viewedHitsRef = useRef(viewedHits)

  const sendViewEvent = () => {
    if (viewedHitsRef.current.length > 0) {
      sendEventView('Resources Viewed', viewedHitsRef.current)
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', sendViewEvent)

    return () => {
      window.removeEventListener('beforeunload', sendViewEvent)
    }
  }, [])

  useEffect(() => {
    viewedHitsRef.current = viewedHits
  }, [viewedHits])

  return hits.length
    ? hits.map((hit, i) => (
        <InView
          as='div'
          onChange={(inView, entry) => {
            if (inView && !viewedHits.includes(hit.objectID)) {
              setViewedHits(prevHits => [...prevHits, hit.objectID])
            }
          }}
        >
          <div className='card border-0 rounded-0 w-100 p-4' key={i}>
            <h5 className='card-category text-uppercase text-navy'>
              {formatCategory(hit.category)}
            </h5>
            <h3 className='card-title text-navy'>{hit.title}</h3>
            <p className='card-description'>{hit.excerpt_text}</p>
            <a
              className='text-info'
              href={hit.url}
              onClick={event => {
                event.preventDefault()
                sendEventView('Resources Viewed', viewedHits)
                sendEventClick('Resources Clicked', hit)
                setViewedHits([])
                navigate(hit.url)
              }}
            >
              {hit.url}
            </a>
          </div>
        </InView>
      ))
    : null
}
const ResultCard = connectHits(_ResultCard)

export const _SearchBox = ({ currentRefinement, refine }) => (
  <input
    autoFocus
    className='form-control'
    type='search'
    placeholder='Search here...'
    value={currentRefinement}
    onChange={event => refine(event.currentTarget.value)}
    style={{ border: `1px solid ${Theme.colors.greyBlue}` }}
  />
)
const SearchBox = connectSearchBox(_SearchBox)

export default ({ location }) => {
  const { state = {} } = location
  const query = pathOr('', ['query'])(state)

  return (
    <Layout bgColor={Theme.colors.lightgrey} location={location}>
      <SEO title='Search' />
      <InstantSearch
        appId={config.appId}
        apiKey={config.apiKey}
        indexName={config.indexName}
      >
        <Fragment>
          <div className='container pt-4'>
            <h2>Search Results</h2>
            <div className='py-3'>
              <SearchBox autoFocus defaultRefinement={query} />
            </div>
            <Configure hitsPerPage={8} />
            <SearchStats />
            <ScrollTo>
              <ResultCard
                sendEventClick={sendEventClick}
                sendEventView={sendEventView}
              />
            </ScrollTo>
          </div>
          <div className='d-flex m-3'>
            <Pagination />
          </div>
        </Fragment>
      </InstantSearch>
    </Layout>
  )
}
