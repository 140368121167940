import { Theme } from '@pga/pga-component-library'
import { Pagination as AlgoliaPagination } from 'react-instantsearch-dom'
import styled from 'styled-components'

export const Pagination = styled(AlgoliaPagination)`
  font-family: ${Theme.fonts.Montserrat};
  padding-top: 10px;

  width: 100%;
  display: flex;
  justify-content: center;

  .ais-Pagination-item {
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    color: ${Theme.colors.lightBlue};
    width: 40px;
    height: 40px;
    padding: 0;
    text-align: center;
    margin: 8px;
  }

  .ais-Pagination-item:hover {
    background-color: transparent;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
  }

  .ais-Pagination-root a:hover {
    text-decoration: none;
  }

  .ais-Pagination-item--page {
    border: 0;
  }

  .ais-Pagination-item--selected {
    background: transparent;
  }

  .ais-Pagination-link {
    display: inherit;
    vertical-align: middle;
    padding: 10px 0;
    color: ${Theme.colors.lightBlue};
    border: 1px solid ${Theme.colors.lightBlue};
    border-radius: 42px;
    width: 42px;
    height: 42px;
  }

  .ais-Pagination-link--selected {
    background-color: ${Theme.colors.lightBlue};
    color: white;
  }

  .ais-Pagination-item--disabled {
    display: none;
  }
  .ais-Pagination-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }
`

export default Pagination
